@layer base {
    @media screen and (min-width: 768px) {
        .container {
            font-size: 1.125rem;
        }
    }

    @media screen and (min-width: 1024px) {
        .container {
            font-size: 1.25rem;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 13.625rem;

    line-height: 1.2;
}

.container h2 {
    color: var(--color-accent-primary);
    text-align: center;
}

@media screen and (min-width: 1024px) {
    .container h2 {
        font-size: 2.5rem;
        text-align: left;
    }
}

.section {
    position: relative;

    padding: 0 1.5rem;
}
