@layer reset {
    /* reset browser styles unrelated to focus */
    .button:not([data-focused][data-focus-visible]) {
        all: unset;
    }
}

@layer base {
    .button {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 3rem;

        font-weight: 600;

        font-size: 1.125rem;

        border-radius: 0.5rem;
        cursor: pointer;
    }

    .primary {
        color: #1E1E1E;

        background: var(--color-accent-primary-gradient);
        border: 2px solid #7250E5;
    }

    .primary[data-pending] {
        cursor: wait;
    }

    .primary[data-disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .primary:not([data-disabled]):not([data-pending]):hover {
        color: var(--color-accent-primary);

        background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.20) 50.03%, rgba(255, 255, 255, 0.10) 100%), #000;
        border: 2px solid var(--color-accent-secondary);
    }

    .primary:not([data-disabled]):not([data-pending])[data-pressed] {
        color: var(--color-accent-primary);

        background: var(--black);
        border: 2px solid var(--black)
    }
}
