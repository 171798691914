.banner {
    position: relative;

    width: 100%;
    overflow: hidden;

    font-size: 1rem;
    font-family: Anton, sans-serif;
    text-align: center;
    text-transform: uppercase;

    background: #07063e url('../../assets/maskImages/Splash.svg');
    background-repeat: no-repeat;
    background-position: center 30%;
    background-size: 120% 120%;
    backdrop-filter: blur(40px);
}

@media screen and (min-width: 768px) {
    .banner {
        background-position: center 30%;

        background-size: 400% 200%;
        backdrop-filter: blur(80px);
    }
}

.linesBackground {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 140%;
    height: 500%;

    background-image: url('./Line.svg');
    background-repeat: repeat;
    background-size: 22px;

    transform: translate(-50%, -50%) rotate(60deg);
    -webkit-backface-visibility: hidden;
}

@media screen and (min-width: 768px) {
    .linesBackground {
        width: 100%;
        height: 100vw;
        mask-image: linear-gradient(-90deg, black calc(45% - 5vw), transparent calc(45% - 5vw), transparent calc(70% - 5vw), black calc(70% - 5vw));
    }
}

@media screen and (min-width: 1024px) {
    .linesBackground {
        mask-image: linear-gradient(-90deg, black calc(45% - 5vw), transparent calc(45% - 5vw), transparent calc(75% - 15vw), black calc(75% - 15vw));

        background-size: 30px;
    }
}

@media screen and (min-width: 1920px) {
    .linesBackground {
        mask-image: linear-gradient(-90deg, black calc(45% - 2vw), transparent calc(45% - 2vw), transparent calc(70% - 12vw), black calc(70% - 12vw));
    }
}

@media screen and (min-width: 2580px) {
    .linesBackground {
        mask-image: linear-gradient(-90deg, black calc(45% - 2vw), transparent calc(45% - 2vw), transparent calc(67.5% - 12vw), black calc(67.5% - 12vw));
    }
}

.banner h1 {
    margin: 0;

    color: transparent;

    font-size: 1.375rem;
    letter-spacing: 0.05rem;

    background-image: var(--color-accent-primary-gradient);
    background-clip: text;
}


@media screen and (min-width: 768px) {
    .banner {
        font-size: 1.5rem;
    }

    .banner h1 {
        font-size: 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .banner {
        font-size: 1.75rem;
    }

    .banner h1 {
        font-size: 2.25rem;
    }
}
