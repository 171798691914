.colors {
    /*colors*/
    --white: #fff;
    --black: #000;
    --green: #24FE01;
    --light-green: #ADFE01;
    --purple: #2C0768;
    --light-purple: #850CFF;
    --transparent-purple: rgba(17, 0, 43, 0.80);
    --light-red: #FF6F6F;
    --gray: #2A2A2A;

    /*design tokens*/
    --color-accent-primary: var(--green);
    --color-accent-primary-gradient: linear-gradient(180deg, var(--light-green) 0%, var(--green) 100%);
    --color-accent-secondary: var(--light-green);

    --color-danger: var(--light-red);

    /*feature tokens*/
    --color-background: var(--purple);
    --color-background-dark: var(--black);
    --color-text-base: var(--white);
    --color-text-dark: var(--black);
    --color-text-interactive: var(--color-accent-primary);

    --color-input-base: var(--white);
    --color-input-surface: var(--transparent-purple);
    --color-input-border: rgba(255, 255, 255, 0.20);

    --color-card-surface: var(--transparent-purple);
}
