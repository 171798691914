@layer reset {
    .trigger:not([data-focused="true"][data-focus-visible="true"]) {
        /* reset browser native button styles unrelated to focus */
        all: unset;
    }

    .trigger[data-focused][data-focus-visible] {
        color: inherit;
        font-family: inherit;

        border: none;
    }
}

.trigger, .content {
    width: 100%;
    padding: 1.25rem 1rem;

    background-color: var(--color-card-surface);

    border-radius: 0.5rem;
}

.trigger {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;

    cursor: pointer;
}

.trigger svg {
    min-width: 1.5rem;
    min-height: 1.5rem;
}

.trigger:not([data-focus-visible="true"]) svg {
    color: var(--color-accent-primary);
}

.content {
    display: none;
}

.content.isOpen {
    display: flex;
}
