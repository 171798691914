@layer base {
    .link {
        color: var(--color-text-interactive);
        text-decoration: underline;

        cursor: pointer;
    }

    /* only show browser default focus outline on keyboard interaction or UA low-visibility designation */
    .link[data-focused='true']:not([data-focus-visible="true"]) {
        outline: none;
    }
}
