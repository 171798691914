.footer {
    background: linear-gradient(352deg, var(--light-purple) -37.58%, var(--color-background) 67.01%);
}

.container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    justify-content: flex-end;
    padding: 6.5rem 1.5rem 7.25rem;
}

@media screen and (min-width: 1024px) {
    .container {
        padding: 6.5rem 1.5rem 16.675rem;
    }
}

.copyright {
    font-weight: 700;
    font-size: 1.125rem;
    text-align: center;
}

.shakiraLogo {
    width: 4.6875rem;
    height: 6.04444rem;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 2.75rem;
    align-items: center;
    width: 100%;
}

.brandContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
}

@media screen and (min-width: 1024px) {
    .mainContainer {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }

    .brandContainer {
        align-items: flex-start;
    }

    .shakiraLogo {
        width: 5.9375rem;
        height: 7.65625rem;
    }
}

.socialsContainer {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
}

.documentationContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 1024px) {
    .documentationContainer {
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
}

.link {
    width: fit-content;
}

.revokeButton {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: 0.5rem 1rem;

    color: white;
    font-size: 1rem;

    background: #07063E;
    border: 1px solid rgba(124, 85, 255, 0.88);
    border-radius: 0.5rem;
    cursor: pointer;
}

.revokeButton:hover {
    color: var(--black);

    background: rgba(191, 173, 253, 0.88);
    border: 1px solid #07063E;
}

.revokedMessage {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.revokedMessage svg {
    color: var(--color-accent-primary);
}

.sublinksContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    justify-content: center;
    padding: 0.75rem 0.625rem 1.5rem;
}

.sublinksContainer > * {
    max-height: 3.75rem;
}

@media screen and (min-width: 768px) {
    .sublinksContainer {
        flex-direction: row;
        justify-content: flex-end;
    }
}

.subLink {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 3rem;

    background: #07063E;
    border: 1px solid rgba(124, 85, 255, 0.88);
    border-radius: 0.5rem;
}

.subLink:hover {
    color: var(--black);

    background: rgba(191, 173, 253, 0.88);
    border: 1px solid #07063E;
}
