@layer base {
    .grid {
        --grid-offset: 2.75rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem 1.4rem;
        width: 100%;
        max-width: 100%;
        padding-bottom: var(--grid-offset);
    }

    .grid > *:nth-child(even) {
        transform: translateY(var(--grid-offset));
    }

    @media screen and (min-width: 744px) {
        .grid {
            --grid-offset: 0;
            grid-template-columns: repeat(3, 1fr);
        }

        .grid > *:nth-child(even) {
            transform: translateY(0);
        }

        .grid > *:nth-child(3n - 1) {
            transform: translateY(var(--grid-offset));
        }
    }

    @media screen and (min-width: 1024px) {
        .grid {
            --grid-offset: 0;
            grid-template-columns: repeat(5, 1fr);

            gap: 2.5rem 1.5rem;
        }

        .grid > *:nth-child(3n - 1) {
            transform: translateY(0);
        }

        .grid > *:nth-child(5n),
        .grid > *:nth-child(5n - 2),
        .grid > *:nth-child(5n - 4) {
            transform: translateY(var(--grid-offset));
        }
    }
}
