.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.entry  {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}

.description {
    margin: 0;
}
