.container {
    display: flex;
    justify-content: space-between;
    height: 3.5rem;
    padding: 0 1.75rem 0 1.45rem;

    color: var(--color-text-base);

    background-color: var(--color-background-dark);
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerRight {
    display: flex;
    gap: 2.5rem;
    align-items: center;
}

.brandLogoLink {
    z-index: 1;

    width: 1.69894rem;
    height: 2.19069rem;
}

.brandLogoLink img {
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 768px) {
    .logoContainer {
        position: relative;

        width: 5.9375rem;
    }

    .brandLogoLink {
        position: absolute;
        top: 75%;
        left: 50%;

        width: 100%;
        height: 7.65625rem;

        transform: translate(-50%, -50%);
    }
}

.container ul {
    padding: 0;

    list-style: none;
}

.desktopNav {
    display: flex;
    gap: 1.375rem;
    height: 100%;
    margin: 0 0 0 1.05rem;
}

.desktopNav .link {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 4.375rem;
    height: 100%;

    font-weight: 600;
    font-size: 1rem;
}

.desktopNav .link.isActive {
    color: var(--color-accent-primary);
}

.desktopNav .link.isActive::after {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 0.5rem;

    background-color: var(--color-accent-primary);

    content: '';
}

.roundBrandContainer {
    display: flex;
    gap: 0.35rem;
    align-items: center;

    color: rgba(255, 255, 255, 0.80);
    font-weight: 500;
    font-size: 0.70625rem;
}

.roundBrandContainer svg {
    flex-shrink: 0;
    width: 5.25rem;
    height: 1.25rem;

    color: #FF0000;
}


.roundCopy {
    display: none;
}

@media screen and (min-width: 768px) {
    .roundBrandContainer {
        font-size: 1rem;
    }

    .roundBrandContainer svg {
        width: 3.675rem;
        height: 1rem;
    }

    .roundCopy {
        display: block;

        margin-bottom: -1px;
    }
}
