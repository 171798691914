.container {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;

    padding: 0.75rem 1rem;

    color: #fff;
    font-size: 0.75rem;

    background-color: #000;
}

@media screen and (min-width: 540px) {
    .container {
        font-size: 0.875rem;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.actionsGroup {
    display: flex;
    gap: 0.5rem;
}

@media screen and (min-width: 768px) {
    .container {
        font-size: 1rem;
    }

    .content {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .actions {
        justify-content: unset;
        width: unset;
    }
}

.content p {
    margin: 0;
}

@layer reset {
    /* reset browser styles unrelated to focus */
    .button:not([data-focused][data-focus-visible]) {
        all: unset;
    }

    .button[data-focused][data-focus-visible] {
        color: inherit;
        font-size: inherit;
        font-family: inherit;

        border: none;
    }
}

.button {
    padding: 0.5rem 1rem;

    color: #000;

    background-color: #fff;
    cursor: pointer;
}

.button.outline {
    color: #fff;

    background-color: transparent;
    border: 1px solid #fff;
}
