.error {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: space-between;

    color: var(--color-danger);
    font-weight: 500;
}

.error svg {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
}
