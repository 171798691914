@layer base {
    .container {
        position: relative;

        width: 100%;
        height: 100%;
    }

    .container:after {
        content: none;
    }

    .container[data-status="loading"]:after {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        animation: pulse 2s linear infinite;

        content: '';
    }

    @keyframes pulse {
        0% {
            background: rgba(191, 54, 255, 0.1);
        }

        50% {
            background: rgba(191, 54, 255, 0.2);
        }

        100% {
            background: rgba(191, 54, 255, 0.1);
        }
    }

    .image {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;

        opacity: 0;

        transition: opacity 0.25s ease-in-out;
    }

    .image[data-status="success"] {
        opacity: 1;
    }
}
