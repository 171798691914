.app {
    composes: colors from 'ui/utils/colors.css';

    min-height: 100dvh;

    color: var(--color-text-base);
    font-family: Archivo, sans-serif;
    line-height: 1.2;

    background-color: var(--color-background);
}
