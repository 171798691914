.banner {
    height: 13.125rem;
}

@media screen and (min-width: 768px) {
    .banner {
        height: 19.1875rem;
    }
}

.shakira {
    position: absolute;
    right: 0;
    bottom: -0.75rem;

    height: 11.375rem;
}

.lamboCar {
    position: absolute;
    bottom: 1.25rem;

    left: -7.125rem;

    width: 19rem;
    height: 8.75rem;
}

@media screen and (min-width: 548px) {
    .lamboCar {
        left: -4rem;
    }
}

@media screen and (min-width: 700px) {
    .shakira {
        right: 0.75rem;

        height: 13.25rem;
    }

    .lamboCar {
        left: -4rem;
    }
}

@media screen and (min-width: 768px) {
    .shakira {
        right: -1rem;
        bottom: -5rem;

        height: 23.813rem;
    }

    .lamboCar {
        bottom: 10%;
        left: -200px;

        width: 33.313rem;
        height: 15.45rem;
    }
}

@media screen and (min-width: 1024px) {
    .shakira {
        right: 0;
    }

    .lamboCar {
        left: -150px;
    }
}

@media screen and (min-width: 1240px) {
    .shakira {
        right: 1.5rem;
    }

    .lamboCar {
        left: -150px;
    }
}

@media screen and (min-width: 1440px) {
    .shakira {
        right: calc(calc(100vw - 1440px) / 2);
    }

    .lamboCar {
        left: calc(calc(calc(100vw - 1244px) / 2) - 150px)
    }
}
