@layer reset {
    /* reset browser defaults, except for browser default focus outline on keyboard
       interaction or UA low-visibility designation  */
    .link:not([data-focused='true']) {
        all: unset;
    }

    .link[data-focused='true'] {
        color: inherit;
        text-decoration: none;
    }

    .link[data-focused='true']:not([data-focus-visible="true"]) {
        outline: none;
    }
}

@layer base {
    .link {
        cursor: pointer;
    }

    .link svg {
        pointer-events: none;
    }
}
