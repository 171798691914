.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    row-gap: 2.75rem;
}

.grid {
    padding-bottom: 0;
}

.grid > * {
    transform: translate(0, 0);
}

@media screen and (min-width: 744px) {
    .grid > * {
        transform: translate(0, 0);
    }
}

@media screen and (min-width: 1024px) {
    .grid > * {
        transform: translate(0, 0);
    }
}

.selected {
    border: 3px solid var(--green);
    box-shadow: 0 0 10px 0 var(--green);
}

.successContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1.5rem;

    font-size: 1.125rem;
}

.successContainer p {
    flex: 1;
}

.successIcon {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--color-accent-primary);
}

@media screen and (min-width: 744px) {
    .successContainer {
        padding: 1.5rem 3rem;
    }
}
