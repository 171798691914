@layer base {
    .container {
        position: relative;
    }

    .dynamicCover {
        position: absolute;
        top: 0;
        left: 0;

        display: none;
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;

        opacity: 0;
    }

    .container:hover .dynamicCover {
        display: block;
    }

    .container:hover .dynamicCover[data-loaded="true"] {
        opacity: 1;
    }
}
