.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
}

.howItWorksContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    max-width: 35.75rem;
    margin: 0 auto;
}

.howItWorksContainer ol {
    margin: 0;
    padding: 0;

    list-style: none;
}

.lamboImage {
    width: 100%;
    object-fit:cover;
}

@media screen and (min-width: 1024px) {
    .container {
        gap: 2rem;
        justify-content: flex-start;
    }

    .howItWorksContainer {
        flex-direction: row;
        gap: 3.5rem;
        width: 100%;
        max-width: unset;
        margin: 0;
    }

    .howItWorksContainer > * {
        flex: 1;
    }

    .lamboImage {
        max-width: 27rem;
    }
}

@media screen and (min-width: 1280px) {
    .lamboImage {
        max-width: 35.75rem;
    }
}

.copyContainer {
    max-width: 35.75rem;
    margin: 0 auto;
}

.copyContainer p {
    margin: 0 0 1rem;
}

.stageList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.listTitle {
    margin-bottom: 1rem;
}

.subTitle {
    margin: 0 0 1.5rem;
}

.stage {
    display: flex;
    gap: 1.5rem;
}

.number {
    color: var(--color-accent-primary);
    font-weight: 700;
}

@media screen and (min-width: 1024px) {
    .stageList {
        gap: 0.875rem;
        justify-content: center;

        font-size: 1.5rem;
    }

    .subTitle {
        font-size: 1.5rem;
    }

    .copyContainer {
        max-width: unset;
    }
}
