@media screen and (min-width: 768px) {
    .header {
        padding: 2.125rem 0 0;
    }
}

.sublinksContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0.625rem 1.5rem;
}

@media screen and (min-width: 768px) {
    .sublinksContainer {
        justify-content: flex-end;
    }
}

.subLink {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;

    background: #07063E;
    border: 1px solid rgba(124, 85, 255, 0.88);
    border-radius: 0.5rem;
}

.subLink:hover {
    color: var(--black);

    background: rgba(191, 173, 253, 0.88);
    border: 1px solid #07063E;
}
