.player {
    position: relative;

    width: 100%;
    max-width: 13.75rem;
    height: 100%;
    overflow: hidden;

    border-radius: 0.75rem;
    aspect-ratio: 0.73 / 1;
}

.winner {
    border: 3px solid var(--green);
    box-shadow: 0 0 10px 0 var(--green);
}

.player[data-hocus]:not([data-error]) {
    cursor: pointer;
}

.error {
    position: absolute;

    top: 65%;
    left: 50%;

    display: none;
    width: 75%;
    padding: 0.25rem;

    color: white;
    font-size: 0.75rem;

    text-align: center;

    background-color: rgb(0, 0, 0, 0.75);
    border-radius: 0.25rem;

    transform: translate(-50%, -50%);
}

.player[data-error] .error {
    display: block;
}

.mediaProvider {
    position: relative;

    width: 100%;
    height: 100%;
}

.mediaProvider video {
    width: 100%;
    height: 100%;

    object-fit: cover;
}

.player[data-fullscreen] .mediaProvider video {
    object-fit: contain;
}

.platformLink {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    z-index: 2;

    display: flex;
    flex-direction: row;
    align-items: center;

    color: white;

    font-size: 0.75rem;

    line-height: 0;
    text-decoration: none;

    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.375rem;

    transition: background-color 0.25s ease-in-out;
}

.platformLink:hover,
.player[data-error] .platformLink {
    padding-right: 0.25rem;

    background-color: black;
}

.platformLink span {
    width: 0;
    overflow: hidden;

    transition: width 0.25s ease-in-out;
}

.platformLink:hover span,
.player[data-error] .platformLink span {
    width: auto;
    overflow: initial;
}

.platformLink > * {
    pointer-events: none;
}

.platformIcon {
    width: 1.5rem;
    height: 1.5rem;
}

.poster {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}


.player[data-fullscreen] .poster,
.player[data-playing] .poster,
.player[data-started] .poster {
    visibility: hidden;
}

.playToggleGesture {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.button {
    padding: 0;

    background: transparent;
    border: none;

    cursor: pointer;
}

.playToggleButton {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;

    color: white;

    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.pauseIcon,
.playIcon,
.playbackLoadingIcon {
    display: none;
}

.player[data-playing][data-controls] .pauseIcon {
    display: block;
}

.player[data-hocus][data-paused]:not([data-error]):not([data-buffering]) .playIcon,
.player[data-paused]:not([data-error]):not([data-buffering]) .playToggleButton.persist .playIcon {
    display: block;
}

.player[data-buffering] .playbackLoadingIcon {
    display: block;
}

.bottomShade {
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 4rem;

    background: linear-gradient(0deg, #000000 9.37%, rgba(0, 0, 0, 0) 100%);
}

.controls {
    position: absolute;
    bottom: 0;
    z-index: 1;

    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    max-width: 100%;
}

.track {
    --media-slider-track-height: 3px;
    --media-slider-thumb-size: 10px;
    --media-slider-track-bg: rgb(255 255 255 / 0.3);
    --media-slider-track-fill-bg: #f5f5f5;
    --media-slider-track-progress-bg: rgb(255 255 255 / 0.5);
    grid-row: 2;

    grid-column: 1 / 3;

    width: auto;
    height: 0.75rem;

    opacity: 0;

    transition: height 0.2s linear, opacity 0.2s linear;

    pointer-events: none;
}

.player[data-fullscreen] .track {
    margin-bottom: 0.5rem;
}

.player[data-playing] .track,
.player[data-started][data-hocus] .track,
.player[data-started][data-hover] .track {
    height: 1rem;

    opacity: 1;

    pointer-events: auto;
}

.userInfo {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 2;
    align-items: flex-start;
    justify-content: flex-end;

    padding-left: 0.75rem;
}

.accountHandle {
    font-weight: 700;
    font-size: 0.75rem;
    font-family: Archivo, sans-serif;
}

.viewCount {
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 0.6125rem;
    font-family: Archivo, sans-serif;
}

.viewCountIcon {
    width: 0.75rem;
    height: 0.75rem;
}

.bottomRightControls {
    --bottom-right-controls-size: 1.5rem;
    display: flex;
    flex-direction: column-reverse;

    grid-column: 2 / 3;
    gap: 0.25rem;
    padding-right: 0.25rem;


    opacity: 0;

    transition: opacity 0.2s linear;

    pointer-events: none
}

.bottomRightControls * {
    line-height: 0;
}

.player[data-playing] .bottomRightControls,
.player[data-started][data-hocus] .bottomRightControls,
.player[data-started][data-hover] .bottomRightControls {
    opacity: 1;

    pointer-events: auto;
}

.bottomRightControls .icon {
    width: var(--bottom-right-controls-size);
    height: var(--bottom-right-controls-size);
}

.player[data-fullscreen] .bottomRightControls .icon {
    width: calc(var(--bottom-right-controls-size) * 1.5);
    height: calc(var(--bottom-right-controls-size) * 1.5);
    padding: 0.5rem;
}

.fullscreenButton:not([data-supported]) {
    display: none;
}

.fullscreenButton[data-active] .fullscreenIcon {
    display: none;
}

.fullscreenButton:not([data-active]) .fullscreenExitIcon {
    display: none;
}

.muteIcon,
.unmuteIcon {
    display: none;
}

.muteButton[data-muted] .muteIcon {
    display: block;
}

.muteButton:not([data-muted]) .unmuteIcon {
    display: block;
}

.tooltip {
    --default-color: #f5f5f5;
    --default-bg: rgb(0, 0, 0, 0.75);
    --default-border: none;
    --media-tooltip-x-offset: 0.25rem;
    --media-tooltip-border-radius: 0.25rem;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 1rem;

    font-size: 0.75rem;
}

.tooltip:after {
    position: absolute;
    top: 50%;
    right: 0;

    display: block;

    width: 0;
    height: 0;

    border-top: 0.2rem solid transparent;
    border-bottom: 0.2rem solid transparent;
    border-left: 0.2rem solid rgb(0, 0, 0, 0.75);
    transform: translate(100%, -50%);

    content: '';
}
