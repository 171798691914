.banner {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    align-items: center;
    justify-content: center;
    padding: 2.75rem 4.5rem 1.75rem;
}

.headlines {
    z-index: 2;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.headlines p {
    margin: 0;
}

@media screen and (min-width: 768px) {
    .banner {
        gap: 1.5rem;
        height: 23rem;
        padding: 4.15rem 1.5rem 1.5rem;
    }

    .headlines {
        max-width: min(70%, 850px)
    }
}

@media screen and (min-width: 1024px) {
    .banner {
        gap: 1.5rem;
        padding: 4.15rem 1.5rem 1.5rem;
    }
}

.joinLink {
    z-index: 2;

    padding: 0.5rem 1.5rem;

    color: black;

    font-size: 1rem;

    background: var(--color-accent-primary-gradient), #24FE01;
    border-radius: 0.5rem;
}

@media screen and (min-width: 1024px) {
    .joinLink {
        font-size: 1.5rem;
    }
}

.shakira {
    position: absolute;
    right: -2.25rem;
    bottom: -0.75rem;

    height: 8.25rem;
}

.lamboCar {
    position: absolute;
    bottom: 0.25rem;
    left: -60px;

    width: 11.5rem;
    height: 6rem;
}

@media screen and (min-width: 548px) {
    .shakira {
        right: -3.25rem;

        height: 11.25rem;
    }

    .lamboCar {
        left: -50px;

        width: 13rem;
        height: 7rem;
    }
}

@media screen and (min-width: 700px) {
    .shakira {
        right: 0.75rem;

        height: 13.25rem;
    }

    .lamboCar {
        bottom: 1rem;

        width: 16.5rem;
        height: 9rem;
    }
}

@media screen and (min-width: 1024px) {
    .shakira {
        bottom: 0;

        height: 14rem;
    }

    .lamboCar {
        bottom: 8%;

        width: 19rem;
        height: 10.5rem;
    }
}

@media screen and (min-width: 1240px) {
    .shakira {
        right: 1.5rem;

        height: 16.5rem;
    }

    .lamboCar {
        bottom: 10%;
        left: -25px;

        width: 21.75rem;
        height: 11.5rem;;

    }
}

@media screen and (min-width: 1440px) {
    .shakira {
        right: calc(calc(calc(100vw - 1244px) / 2) - 50px);
    }

    .lamboCar {
        left: calc(calc(calc(100vw - 1244px) / 2) - 100px)
    }
}
